import AirDatepicker from 'air-datepicker';
import localePl from 'air-datepicker/locale/pl';
import { DateTime } from 'luxon';

class MenuToggle {
  constructor(buttonSelector, headerSelector, bodySelector) {
      this.menuButton = document.querySelector(buttonSelector);
      this.header = document.querySelector(headerSelector);
      this.body = document.querySelector(bodySelector);
      this.init();
  }

  init() {
    this.menuButton.addEventListener('click', () => this.toggleMenu());
  }

  toggleMenu() {
      this.body.classList.toggle('menu-open');
      this.header.classList.toggle('menu-open');
      this.menuButton.classList.toggle('active');
  }

  closeMenu() {
      this.body.classList.remove('menu-open');
      this.header.classList.remove('menu-open');
      this.menuButton.classList.remove('active');
  }
}

const menuToggle = new MenuToggle('.header__mobile-btn', '.header', 'body');
window.menuToggleInstance = menuToggle;

document.querySelectorAll('.js-scroll-link').forEach(link => {
  link.addEventListener('click', (e) => {
      e.preventDefault();
      const targetId = link.getAttribute('href');
      
      // Проверка, что targetId начинается с #
      if (targetId.startsWith('#')) {
          const targetSection = document.querySelector(targetId);
          
          // Проверка, что targetSection существует
          if (targetSection) {
              const sectionPosition = targetSection.getBoundingClientRect().top + window.scrollY - 120;
              window.scrollTo({
                  top: sectionPosition,
                  behavior: 'smooth'
              });
              window.menuToggleInstance.closeMenu();
          }
      }
  });
});


class TabsManager {
  /**
   * Конструктор класса, который принимает контейнер меню и инициализирует работу табов
   * @param {HTMLElement} container - Корневой элемент с классом .menu
   */
  constructor(container) {
    this.container = container;
    this.menuNavItems = this.container.querySelectorAll('.menu__nav-item');
    this.menuTabs = this.container.querySelectorAll('.menu__tab');
    
    // Инициализируем работу меню навигации
    this.initMenuTabs();

    // Инициализируем работу day-nav внутри каждой вкладки меню
    this.menuTabs.forEach(tab => this.initDayTabs(tab));
  }

  /**
   * Метод для установки активного таба
   * @param {NodeList} items - Список элементов навигации (либо .menu__nav-item, либо .day-nav__item)
   * @param {NodeList} tabs - Список табов, которые соответствуют элементам навигации
   * @param {number} index - Индекс активного элемента
   */
  setActiveTab(items, tabs, index) {
    // Убираем активные классы у всех элементов и табов
    items.forEach(item => item.classList.remove('active'));
    tabs.forEach(tab => tab.classList.remove('active'));

    // Добавляем активный класс к выбранному элементу и табу
    items[index].classList.add('active');
    tabs[index].classList.add('active');
  }

  /**
   * Инициализация работы табов меню навигации
   */
  initMenuTabs() {
    this.menuNavItems.forEach((item, index) => {
      item.addEventListener('click', () => {
        this.setActiveTab(this.menuNavItems, this.menuTabs, index);
      });
    });
  }

  /**
   * Инициализация работы day-nav табов внутри каждой вкладки меню
   * @param {HTMLElement} menuTab - Элемент с классом .menu__tab, внутри которого находятся day-nav
   */
  initDayTabs(menuTab) {
    const dayNavItems = menuTab.querySelectorAll('.day-nav__item');
    const dayTabs = menuTab.querySelectorAll('.menu__day-tab');
    
    if (dayNavItems.length) {
      dayNavItems.forEach((item, index) => {
        item.addEventListener('click', () => {
          this.setActiveTab(dayNavItems, dayTabs, index);
        });
      });
    }
  }

  /**
   * Статический метод для инициализации всех блоков меню на странице
   */
  static initAll() {
    document.querySelectorAll('.menu').forEach(menu => new TabsManager(menu));
  }
}

TabsManager.initAll();




class ModalManager {
  constructor() {
    this.modalButtons = document.querySelectorAll('[data-modal-id]');
    this.openModals = new Set(); // Track open modals
    this.initialize();
  }

  initialize() {
    this.modalButtons.forEach(button => {
      button.addEventListener('click', event => {
        const modalId = button.dataset.modalId;
        this.openModal(modalId);
        
        event.stopPropagation(); // Stop immediate closing
      });
    });

    // Handle click on close button
    document.querySelectorAll('[data-modal-close]').forEach(button => {
      button.addEventListener('click', event => {
        const modal = button.closest('.modal');
        this.closeModal(modal);
        event.stopPropagation(); // Prevent triggering click outside logic
      });
    });

    // Handle click outside the modal
    document.addEventListener('click', event => {
      const modals = document.querySelectorAll('.modal.show');
      let clickedInsideModal = false;

      modals.forEach(modal => {
        if (modal.contains(event.target)) {
          clickedInsideModal = true;
        }
      });

      if (!clickedInsideModal) {
        modals.forEach(modal => {
          this.closeModal(modal); // Close the modal if clicked outside
        });
      }
    });
  }

  openModal(modalId) {
    // Close all currently open modals
    this.openModals.forEach(modal => this.closeModal(modal));

    // Open the new modal
    const modal = document.getElementById(modalId);
    if (modal) {
      modal.classList.add('show');
      this.openModals.add(modal); // Add modal to the set of open modals
      document.querySelector('body').classList.add('is-modal');

      window.menuToggleInstance.closeMenu();
    }
  }

  closeModal(modal) {
    if (modal && this.openModals.has(modal)) {
      modal.classList.remove('show');
      this.openModals.delete(modal); // Remove modal from the set of open modals
    }
    if (this.openModals.size === 0) {
      document.querySelector('body').classList.remove('is-modal');
    }
  }
}


window.modalManager = new ModalManager();

class SyncInputRange {
  constructor(numberInput, rangeInput) {
    if (!numberInput || !rangeInput) {
      return;
    }

    this.numberInput = numberInput;
    this.rangeInput = rangeInput;

    // Инициализация начальных значений
    this.rangeInput.value = this.numberInput.value || this.rangeInput.min; // Устанавливаем значение из numberInput или минимальное значение
    this.updateBackground(); // Обновляем фон сразу при инициализации

    // Добавляем обработчики событий
    this.addEventListeners();
  }

  addEventListeners() {
    // Обновляем range, если меняется number
    this.numberInput.addEventListener('input', () => {
      let value = this.numberInput.value.replace(/\D/g, '');
      const min = parseInt(this.numberInput.min);
      const max = parseInt(this.numberInput.max);

      if (value > max) {
        value = max.toString();
      }

      if (value === '') {
        value = min.toString();
      }

      this.numberInput.value = value;
      this.rangeInput.value = value;
      this.updateBackground();
    });

    // Обновляем number, если меняется range
    this.rangeInput.addEventListener('input', () => {
      this.numberInput.value = this.rangeInput.value;
      this.updateBackground();
    });
  }

  updateBackground() {
    const value = ((this.rangeInput.value - this.rangeInput.min) / (this.rangeInput.max - this.rangeInput.min)) * 100;
    this.rangeInput.style.background = `linear-gradient(to right, #0056d3 ${value}%, #d7d7d7 ${value}%)`;
  }
}

// Инициализация SyncInputRange для каждого поля
document.querySelectorAll('.calc__field').forEach(field => {
  const numberInput = field.querySelector('input[type="tel"]');
  const rangeInput = field.querySelector('input[type="range"]');
  
  if (numberInput && rangeInput) {
    new SyncInputRange(numberInput, rangeInput);
  }
});

// Обработчик клика на кнопку расчета
class CalorieCalculator {
  constructor(buttonSelector) {
    this.button = document.querySelector(buttonSelector);

    if(this.button){
      this.init();
    }
  }

  init() {
      this.button.addEventListener('click', () => this.calculate());
  }

  getValues() {
      const weight = parseFloat(document.querySelector('input[name="weight"]').value);
      const height = parseFloat(document.querySelector('input[name="height"]').value);
      const age = parseInt(document.querySelector('input[name="age"]').value);
      const gender = document.querySelector('select[name="gender"]').value;
      const activity = parseFloat(document.querySelector('select[name="activity"]').value);
      return { weight, height, age, gender, activity };
  }

  calculateBMR({ weight, height, age, gender }) {
      if (gender === "male") {
        return 88.362 + 13.397 * weight + 4.799 * height - 5.677 * age;
      } else {
        return 447.593 + 9.247 * weight + 3.098 * height - 4.33 * age;
      }
  }

  calculateCalories(bmr, activity) {
      return bmr * activity;
  }

  getRecommendedCalories(calories) {
      const caloriePool = [1200, 1500, 1800, 2100, 2400];

      if (calories < caloriePool[0]) {
          return caloriePool[0];
      } else if (calories <= caloriePool[1]) {
          return caloriePool[1];
      } else if (calories <= caloriePool[2]) {
          return caloriePool[2];
      } else if (calories <= caloriePool[3]) {
          return caloriePool[3];
      } else {
          return caloriePool[4];
      }
  }

  updateUI(bmr, calories, recommendedCalories) {
      document.querySelector('.calc .bmr').textContent = Math.round(bmr);
      document.querySelector('.calc .calories').textContent = Math.round(calories);
      document.querySelector('.calc .recomend').textContent = recommendedCalories;
      document.querySelector('.calc .btn-recomend').textContent = recommendedCalories;
  }

  calculate() {
      const { weight, height, age, gender, activity } = this.getValues();
      const bmr = this.calculateBMR({ weight, height, age, gender });
      const calories = this.calculateCalories(bmr, activity);
      const recommendedCalories = this.getRecommendedCalories(calories);
      this.updateUI(bmr, calories, recommendedCalories);
  }
}

const calorieCalculator = new CalorieCalculator('.calc__btn');

class PayCalendarWidget {
  constructor(widgetElement) {
    this.widgetElement = widgetElement;
    this.inputs = this.widgetElement.querySelectorAll('.pay-widget__calendar');
    this.items = this.widgetElement.querySelectorAll('.pay-item');
    this.selectedInfo = this.widgetElement.querySelector('.pay-widget__row.hidden');
    this.card = this.selectedInfo.querySelector('.pay-full-card');
    this.payTotal = document.querySelector('.pay-total');
    this.title = this.selectedInfo.querySelector('.pay-full-card__title');
    this.cost = this.selectedInfo.querySelector('.cost .price span');
    this.oldPrice = this.selectedInfo.querySelector('.cost .price__old');
    this.qn = this.selectedInfo.querySelector('.pay-full-card__qn');
    this.dates = this.selectedInfo.querySelector('.pay-full-card__dates');
    

    this.fullCost = this.selectedInfo.querySelector('.full-cost .price span');
    this.fullCostOld = this.selectedInfo.querySelector('.full-cost .price__old');

    this.discount1Element = this.selectedInfo.querySelector('.pay-full-card__discount1');
    this.discount2Element = this.selectedInfo.querySelector('.pay-full-card__discount2');
    this.discount3Element = this.selectedInfo.querySelector('.pay-full-card__discount3');

    this.originalCard = this.widgetElement.cloneNode(true);

    this.datepickers = [];

    this.initDatepickers();
    this.initItems();
    this.initRemoveButton();
  }

  initRemoveButton() {
    const removeButton = this.widgetElement.querySelector('.pay-widget__remove');
    if (removeButton) {
      removeButton.addEventListener('click', () => {
        const remainingWidgets = document.querySelectorAll('.pay-widget');
        
        // Если остается больше одного виджета, разрешаем удаление
        if (remainingWidgets.length > 1) {
          this.widgetElement.remove();
          
          // Обновляем состояние кнопки удаления у оставшегося виджета
          if (remainingWidgets.length - 1 === 1) {
            const lastWidget = document.querySelector('.pay-widget');
            const lastRemoveButton = lastWidget.querySelector('.pay-widget__remove');
            if (lastRemoveButton) {
              lastRemoveButton.style.display = 'none';
            }
          }
        } else {
          // Скрываем кнопку удаления для последнего виджета
          removeButton.style.display = 'none';
        }
      });
    }
  }
  

  initDatepickers() {
    const today = new Date();
    const endOfYear = new Date(today.getFullYear(), 11, 31);
    const maxDate = new Date(endOfYear);
    maxDate.setMonth(maxDate.getMonth() + 1);

    this.inputs.forEach((input, index) => {
      const datepicker = new AirDatepicker(input, {
        inline: true,
        locale: localePl,
        minDate: today,
        maxDate: maxDate,
        multipleDates: true,
        multipleDatesSeparator: ' , ',
        onSelect: ({ date, formattedDate }) => {
          const selectedDates = Array.isArray(date) ? date : [date];
          input.value = formattedDate;
          this.updateSelectedDates(input);
          this.updateSelectedDatesCount(selectedDates.length);
          this.updateCost();
        },
        keyboardNav: false,
        onRenderCell: ({ date }) => {
          const nowInPoland = DateTime.now().setZone('Europe/Warsaw');
          const isToday = date.toDateString() === today.toDateString();
          const isAfter9PM = nowInPoland.hour >= 21;

          if (isToday) { // Логировать только для сегодняшней даты
            console.log("Сегодня:", isToday, "После 21:00:", isAfter9PM);
          }

          if (isToday && isAfter9PM) {
            return { disabled: true };
          }
        }
      });

      this.datepickers.push(datepicker);
    });
  }

  resetWidget() {
    this.selectedInfo.classList.add('hidden');

    this.inputs.forEach((input, index) => {
      input.value = ''; 
      const datepicker = this.datepickers[index];
      datepicker.clear();
    });

    this.items.forEach(item => {
      item.classList.remove('active');
      const btn = item.querySelector('.pay-item__btn');
      if (btn) {
        btn.textContent = 'Wybierać';
      }
    });

    this.title.textContent = '';
    this.cost.textContent = '';
    this.oldPrice.textContent = '';
    this.qn.textContent = '1';
    this.fullCost.textContent = '';
    this.fullCostOld.textContent = '';
    this.dates.textContent = 'nie wybrano';
  }

  

  initItems() {
    this.items.forEach(item => {
      item.addEventListener('click', () => {
        this.handleItemClick(item);
        this.updatePayTotal();
        this.updateCost();
      });
    });
  }

  handleItemClick(selectedItem) {

    this.items.forEach(item => {
      item.classList.remove('active');
      const btn = item.querySelector('.pay-item__btn');
      if (btn) {
        btn.textContent = 'Wybierać';
      }
    });
    selectedItem.classList.add('active');

    const selectedBtn = selectedItem.querySelector('.pay-item__btn');
    if (selectedBtn) {
      selectedBtn.textContent = 'Wybrany';
    }

    const calories = selectedItem.getAttribute('data-calories');
    const price = parseFloat(selectedItem.getAttribute('data-price')); // Извлекаем цену как число
    const discount = parseFloat(selectedItem.getAttribute('data-discount')); // Извлекаем скидку
    const discount2 = parseFloat(selectedItem.getAttribute('data-discount2'));
    const discount3 = parseFloat(selectedItem.getAttribute('data-discount3'));
    const image = selectedItem.querySelector('.pay-item__photo img');

    if (image) {
      const imageSrc = image.getAttribute('src');
      const cardImage = this.card.querySelector('.pay-full-card__photo img'); // Предполагаем, что изображение в this.card также имеет тег <img>
      if (cardImage) {
        cardImage.setAttribute('src', imageSrc);
      }
    }

    this.title.textContent = `${calories} kalorii`;
    this.card.setAttribute('data-price', price);
    this.card.setAttribute('data-discount', discount);
    this.card.setAttribute('data-discount2', discount2);
    this.card.setAttribute('data-discount3', discount3);
    this.selectedInfo.classList.remove('hidden');

    this.updateCost();
  }

  updateCost() {
    setTimeout(() => {
      const cardPackgs = parseInt(this.card.getAttribute('data-selected-dates'), 10) || 1;
      const price = parseFloat(this.card.getAttribute('data-price')) || 0;
      const discount = parseFloat(this.card.getAttribute('data-discount')) || 0;
      const discount2 = parseFloat(this.card.getAttribute('data-discount2')) || 0;
      const discount3 = parseFloat(this.card.getAttribute('data-discount3')) || 0;

      // Используем declinePackages для отображения правильного окончания
      this.qn.textContent = `${cardPackgs} ${this.declinePackages(cardPackgs)}`;

      let finalPrice = price;
      switch (true) {
        case (cardPackgs >= 28):
          const discountAmount3 = (finalPrice * discount3) / 100;
          finalPrice -= discountAmount3;
          const totalPrice28 = cardPackgs * finalPrice;
          this.cost.textContent = `${finalPrice.toFixed(2)}zł`;
          this.oldPrice.textContent = `${price.toFixed(2)}zł`;
          this.fullCost.textContent = `${totalPrice28.toFixed(2)}zł`;
          this.fullCostOld.textContent = `${(cardPackgs * price).toFixed(2)}zł`;
          break;
        case (cardPackgs >= 24):
          const discountAmount2 = (finalPrice * discount2) / 100;
          finalPrice -= discountAmount2;
          const totalPrice24 = cardPackgs * finalPrice;
          this.cost.textContent = `${finalPrice.toFixed(2)}zł`;
          this.oldPrice.textContent = `${price.toFixed(2)}zł`;
          this.fullCost.textContent = `${totalPrice24.toFixed(2)}zł`;
          this.fullCostOld.textContent = `${(cardPackgs * price).toFixed(2)}zł`;
          break;
        case (cardPackgs >= 20):
          const discountAmount = (finalPrice * discount) / 100;
          finalPrice -= discountAmount;
          const totalPrice20 = cardPackgs * finalPrice;
          this.cost.textContent = `${finalPrice.toFixed(2)}zł`;
          this.oldPrice.textContent = `${price.toFixed(2)}zł`;
          this.fullCost.textContent = `${totalPrice20.toFixed(2)}zł`;
          this.fullCostOld.textContent = `${(cardPackgs * price).toFixed(2)}zł`;
          break;
        default:
          const totalPriceDefault = cardPackgs * price;
          this.cost.textContent = `${price.toFixed(2)}zł`;
          this.fullCost.textContent = `${totalPriceDefault.toFixed(2)}zł`;
          this.oldPrice.textContent = '';
          this.fullCostOld.textContent = '';
          break;
      }

      this.updatePayTotal();
    }, 5);
}

updatePayTotal() {
    const allWidgets = document.querySelectorAll('.pay-widget');
    let totalPackages = 0;
    let totalPrice = 0;
    let totalDiscount = 0;
    let totalPriceWithoutDiscount = 0;

    allWidgets.forEach(widget => {
      const card = widget.querySelector('.pay-full-card');
      const selectedDates = parseInt(card.getAttribute('data-selected-dates'), 10) || 0;
      const price = parseFloat(card.getAttribute('data-price')) || 0;
      const discount = parseFloat(card.getAttribute('data-discount')) || 0;
      const discount2 = parseFloat(card.getAttribute('data-discount2')) || 0;
      const discount3 = parseFloat(card.getAttribute('data-discount3')) || 0;

      totalPackages += selectedDates;
      totalPriceWithoutDiscount += selectedDates * price;

      let finalDiscount = 0;
      if (selectedDates >= 28) {
        finalDiscount = (price * discount3) / 100;
      } else if (selectedDates >= 24) {
        finalDiscount = (price * discount2) / 100;
      } else if (selectedDates >= 20) {
        finalDiscount = (price * discount) / 100;
      }
      totalDiscount += selectedDates * finalDiscount;
      totalPrice += selectedDates * (price - finalDiscount);
    });

    // Обновляем текст для количества пакетов
    this.payTotal.querySelector('.pay-total__item.packgs span').textContent = 
      `${totalPackages} ${this.declinePackages(totalPackages)}`;
    this.payTotal.querySelector('.pay-total__item.total span').textContent = `${totalPriceWithoutDiscount.toFixed(2)}zł`;
    this.payTotal.querySelector('.pay-total__item.discount span').textContent = `${totalDiscount.toFixed(2)}zł`;
    this.payTotal.querySelector('.pay-total__item.sum span').textContent = `${totalPrice.toFixed(2)}zł`;
}

// Функция для склонения слова "пакет"
declinePackages(count) {
  const mod10 = count % 10;
  const mod100 = count % 100;

  if (mod10 === 1 && mod100 !== 11) return "pakiet";
  if (mod10 >= 2 && mod10 <= 4 && (mod100 < 10 || mod100 >= 20)) return "pakiety";
  return "pakietów";
}



  updateSelectedDates(input) {
    const payFullCard = this.selectedInfo.querySelector('.pay-full-card__dates');
    if (payFullCard) {
      // Преобразуем строки дат в массив, добавляем пробелы и объединяем их
      const formattedDates = input.value.split(',').map(date => date.trim()).join(', ');
      payFullCard.textContent = formattedDates;
    }
  }
  
  

  updateSelectedDatesCount(selectedDatesCount) {
    const payFullCard = this.selectedInfo.querySelector('.pay-full-card');
    if (payFullCard) {
      payFullCard.setAttribute('data-selected-dates', selectedDatesCount);
    }
  }

  cloneCard() {
    // Создаем новую копию из сохраненной структуры
    const newCard = this.originalCard.cloneNode(true);

    // При необходимости можно добавить новую карточку в DOM
    this.widgetElement.appendChild(newCard);

    // Повторно инициализируем функционал для нового элемента
    new PayCalendarWidget(this.widgetElement);
  }
}

document.querySelectorAll('.pay-widget').forEach(widgetElement => {
  new PayCalendarWidget(widgetElement);
});
document.addEventListener('DOMContentLoaded', () => {
  const widgetTemplate = document.querySelector('.pay-widget');

  if (widgetTemplate) {
    // Клонируем шаблон pay-widget при загрузке страницы
    const template = widgetTemplate.cloneNode(true);

    // Скрываем кнопку удаления, если на странице один виджет
    const updateRemoveButtonVisibility = () => {
      const widgets = document.querySelectorAll('.pay-widget');
      widgets.forEach((widget, index) => {
        const removeButton = widget.querySelector('.pay-widget__remove');
        if (removeButton) {
          removeButton.style.display = widgets.length > 1 ? 'flex' : 'none';
        }
      });
    };
    updateRemoveButtonVisibility();

    // Событие для кнопки "pay-widget__more"
    document.querySelector('.pay-widget__more').addEventListener('click', () => {
      // Клонируем шаблон и добавляем его в DOM
      const newWidget = template.cloneNode(true);

      // Удаляем блок air-datepicker, если он есть в новом виджете
      const datepickerInline = newWidget.querySelector('.air-datepicker.-inline-');
      if (datepickerInline) {
        datepickerInline.remove();
      }

      // Добавляем новый виджет в нужный контейнер
      document.querySelector('.product-tab').appendChild(newWidget);

      // Инициализация нового виджета
      new PayCalendarWidget(newWidget);

      // Обновляем видимость кнопок удаления после добавления нового виджета
      updateRemoveButtonVisibility();
    });
  }
});



// let formsStatic = document.querySelector('.js-form');

// if(formsStatic){
//   let textarea = formsStatic.querySelector('textarea');

//   document.querySelector('.feedback .calc-modal__close').addEventListener('click', function(){
//     document.querySelector('.feedback').classList.remove('show-modal');
//   });

//   textarea.addEventListener('input', function(){
//     if(this.value.length > 0){
//       textarea.parentNode.classList.remove('has-error');
//       textarea.parentNode.classList.remove('has-error-charters');
//     }
//   });

//   formsStatic.addEventListener('submit', function(e) {
//     const feedbackElement = document.querySelector('.feedback');
    
//       e.preventDefault();
//       let name = formsStatic.querySelector('input[type="text"]');
//       let email = formsStatic.querySelector('input[type="email"]');

//       if (textarea.value.length >= 10) {
//           var request = new XMLHttpRequest();

//           request.onreadystatechange = function() { 
//               if (this.readyState === XMLHttpRequest.DONE) {
//                   if (this.status === 200) {
//                       const response = JSON.parse(this.responseText);
//                       if (response.success) {
//                           name.value = "";
//                           email.value = "";
//                           textarea.value = "";

//                           textarea.parentNode.classList.remove('has-error');
//                           textarea.parentNode.classList.remove('has-error-charters');

//                           feedbackElement.classList.add('show-modal');
//                           setTimeout(() => {
//                             feedbackElement.classList.remove('show-modal');
//                           }, 4000);
//                       } else {
//                           console.log('Errors:', response.errors);
//                       }
//                   } else {
//                       console.error('Ошибка при отправке запроса:', this.status);
//                   }
//               }
//           };

//           request.open(formsStatic.method, formsStatic.action, true);
//           var data = new FormData(formsStatic);
//           request.send(data);
//       } else {
//         textarea.parentNode.classList.add('has-error');

//         if(textarea.value.length > 0 && textarea.value.length < 10){
//           textarea.parentNode.classList.add('has-error');
//           textarea.parentNode.classList.add('has-error-charters');
//         }
//       }
//   });
// }


class PayTabs {
  constructor(tabsSelector, itemsSelector, prevButtonSelector, stepsLineSelector) {
    this.tabs = document.querySelectorAll(tabsSelector);
    this.items = document.querySelectorAll(itemsSelector);
    this.prevButtons = document.querySelectorAll(prevButtonSelector);
    this.steps = document.querySelectorAll(stepsLineSelector);
    this.currentIndex = 0;

    if (this.tabs.length === 0 || this.items.length === 0 || this.prevButtons.length === 0 || this.steps.length === 0) {
      return;
    }

    this.init();
    
  }

  init() {
    this.items.forEach((item, index) => {
      item.addEventListener('click', () => this.onNextClick(index));
    });

    this.prevButtons.forEach((button) => {
      button.addEventListener('click', () => this.onPrevClick());
    });

    document.querySelectorAll('.delivery-widget__input[required]').forEach((input) => {
      input.addEventListener('input', () => this.validateField(input));
    });

    // this.updateTabs(this.currentIndex);
    this.updateButton(this.currentIndex);
    this.updateSteps(this.currentIndex);
  }

  updateTabs(activeIndex) {
    this.tabs.forEach((tab, index) => {
        tab.classList.remove('active');
    });

    this.tabs[activeIndex].classList.add('active');

    // Определяем высоту шапки
    const headerHeight = document.querySelector('header').offsetHeight; // Замените 'header' на ваш селектор шапки

    // Находим позицию активного таба
    const tabPosition = this.tabs[activeIndex].getBoundingClientRect().top + window.scrollY;

    // Прокручиваем страницу к активному табу с учетом высоты шапки
    window.scrollTo({
        top: tabPosition - (headerHeight + 50), // Учитываем высоту шапки
        behavior: 'smooth' // Плавная прокрутка
    });
  }


  updateButton(activeIndex) {
    this.items.forEach((item) => item.classList.remove('active'));
    if (this.items[activeIndex]) {
      this.items[activeIndex].classList.add('active');
    }
  }

  updateSteps(activeIndex) {
    this.steps.forEach((step) => step.classList.remove('active'));
    for (let i = 0; i <= activeIndex; i++) {
      this.steps[i].classList.add('active');
    }
  }

  checkDates() {
    const dateElements = document.querySelectorAll('.pay-full-card__dates');
    return Array.from(dateElements).every((element) => element.textContent.trim() !== "nie wybrano");
  }

  validateField(field) {
    let isValid = true;

    if (field.type === 'text') {
      isValid = field.value.trim().length >= 1;
    } else if (field.type === 'email') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isValid = emailPattern.test(field.value.trim());
    }

    if (isValid) {
      field.classList.remove('error');
    } else {
      field.classList.add('error');
    }

    return isValid;
  }

  checkDeliveryInputs() {
    const requiredInputs = document.querySelectorAll('.delivery-widget__input[required]');
    let allValid = true;

    requiredInputs.forEach((input) => {
      const isValid = this.validateField(input);
      if (!isValid) {
        input.classList.add('error');
        allValid = false;
      }
    });

    return allValid;
  }

  copySelectedCards() {
    const selectedCards = document.querySelectorAll('.pay-full-card[data-selected-dates]');
    const totalInfoGrid = document.querySelector('.total-info__grid');
    const totalInfoData = document.querySelector('.total-info__data');

    totalInfoData.innerHTML = '';


    selectedCards.forEach((card) => {
      const clonedCard = card.cloneNode(true);
      totalInfoGrid.appendChild(clonedCard);
    });

    // Получаем данные из delivery-widget__grid внутри карточки
    const deliveryWidgets = document.querySelectorAll('.delivery-widget__grid .delivery-widget__field');

    deliveryWidgets.forEach((widget) => {
        const label = widget.querySelector('.delivery-widget__label');
        const input = widget.querySelector('.delivery-widget__input');

        if (label && input) {
            const fieldData = document.createElement('div');
            fieldData.classList.add('total-info__field');

            // Заполняем поле и метку
            fieldData.innerHTML = `<strong>${label.textContent}</strong>: ${input.value}`;
            totalInfoData.appendChild(fieldData);
        }
    });
  }


  onNextClick(index) {

    if (index === 0 && !this.checkDates()) {

      document.querySelector('.pay__modal').classList.add('show');

      document.querySelector('.pay__modal .calc-modal__close').addEventListener('click', function(){
        document.querySelector('.pay__modal').classList.remove('show');
      });
      
      return;
    }


    if (index === 1) {
      if (!this.checkDeliveryInputs()) return;
      document.querySelector('.total-info__grid').innerHTML = '';
      this.copySelectedCards();
    }

    if (index < this.tabs.length - 1) {
      this.currentIndex = index + 1;
      this.updateTabs(this.currentIndex);
      this.updateButton(this.currentIndex);
      this.updateSteps(this.currentIndex);
    }
  }

  onPrevClick() {
    if (this.currentIndex > 0) {
      this.currentIndex -= 1;
      this.updateTabs(this.currentIndex);
      this.updateButton(this.currentIndex);
      this.updateSteps(this.currentIndex);
    }
  }
}

new PayTabs('.page-grid__tab', '.pay-total__send', '.pay-total__prev', '.steps-line__item');


class SmoothScrollAndHighlight {
  constructor(menuSelector, sectionSelector) {
    this.links = document.querySelectorAll(menuSelector);
    this.sections = document.querySelectorAll(sectionSelector);
    this.init();
  }

  init() {
      this.bindEvents();
  }

  bindEvents() {
      this.links.forEach(link => {
          link.addEventListener('click', (e) => {
              e.preventDefault();
              const targetId = link.getAttribute('href').substring(1);
              this.scrollToSection(targetId);
          });
      });
  }

  scrollToSection(targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
          targetElement.scrollIntoView({ behavior: 'smooth' });
      }
  }

  activateLink(link) {
      if (link) {
          link.classList.add('active');
      }
  }

  deactivateLink(link) {
      if (link) {
          link.classList.remove('active');
      }
  }
}

// Инициализация класса
document.addEventListener('DOMContentLoaded', () => {
  new SmoothScrollAndHighlight('.page-nav__link', 'h2');
});



let accept = document.querySelector('#accept');
  let submitButton = document.querySelectorAll('.pay-total__send');

  if (accept && submitButton) {
    accept.addEventListener('change', function () {
      if (accept.checked) {
        submitButton.forEach((button) => {
          button.removeAttribute('disabled');
        });
      } else {
        submitButton.forEach((button) => {
          button.setAttribute('disabled', true);
        });
      }
    });
  }